import _ from 'lodash'
import { PromiseHash } from '../helpers/promise-hash'

export interface SchemaResolver {
  /**
   * resolve all $refs in a schema, returning a map of URI to promises. (asynchronous)
   */
  resolveSchema(schema): Promise<{[key: string]: any}>
}

/**
 * Resolves subschema and allows overrides.
 */
export interface SubschemaResolver {
  /**
   * given a context and a subschema, return the resolved subschema.
   */
  resolveSubschema(context, subschema): { context: any; schema: any }
}

export interface RefResolver {
  /**
   * resolve a given schema uri
   */
  resolveReference(context, uri): { context: any; schema: any }
}

// class responsible for resolving local and remote $ref in json schema
export class JSONSchemaResolver implements SchemaResolver, SubschemaResolver, RefResolver {
  public static REF_KEY = '$ref'
  public static ID_KEY = '$id'

  // transform the following:
  // load.stops.0 => load.stops.items
  // document.name => properties.document.properties.name
  public static getSchemaPathFromValuePath(path: string | string[]): string[] {
    if (_.isString(path)) {
      path = String(path).split('.')
    }
    const pathFragments = []
    _.forEach(path, (token) => {
      if (!_.isNaN(parseInt(token, 10)) || token === 'last') {
        pathFragments.push('items')
      } else {
        pathFragments.push('properties')
        pathFragments.push(token)
      }
    })
    return pathFragments
  }

  private api: any
  private store: any
  private refResolver: RefResolver

  /**
   * @param api fulfills API requests
   * @param refResolver schema $ref resolver override (useful for tests)
   */
  constructor(api: any, refResolver?: RefResolver) {
    this.api = api
    this.store = this.api ? this.api.getStore() : null
    this.refResolver = refResolver
  }

  public resolveSchema(schema: any): Promise<{[key: string]: any}> {
    // collect remote references
    const schemaUrls = {}
    this.findRemoteRefsInObject(schema, schemaUrls)
    // make sure we cache all remote reference
    _.forIn(schemaUrls, (value, url) => {
      schemaUrls[url] = this.resolveRemoteSchema(url)
    })
    return PromiseHash(schemaUrls)
  }

  public resolveSubschema(context: any, subschema: any): { context: any; schema: any } {
    const reference = subschema[JSONSchemaResolver.REF_KEY]
    if (!reference) {
      return { context, schema: subschema }
    }
    const resolved = this.resolveReference(context, reference)
    const overrides = subschema.overrides
    if (overrides) {
      subschema = _.cloneDeep(resolved.schema)
      _.forEach(overrides, (value, key) => _.set(subschema, key, value))
    } else {
      // TODO(Peter): Deprecated: old way of overriding, not explicit!
      subschema = _.assign({}, resolved.schema, subschema)
    }
    return { context: resolved.context, schema: subschema }
  }

  public resolveReference(context: any, uri: any): { context: any; schema: any } {
    if (this.refResolver) {
      // use ref resolver override
      const result = this.refResolver.resolveReference(context, uri)
      if (result) {
        return result
      }
    }

    // extract uri components
    const baseUri = this.extractReferenceUrl(uri)
    const fragment = uri.replace(baseUri, '') || undefined

    // resolve entity, and subschema if uri contains a fragment
    const resolvedContext = baseUri ? this.store.getRecord(baseUri) : context
    const schema = fragment ? _.get(resolvedContext, fragment.split('/').slice(1)) : resolvedContext

    if (!schema) {
      const details = !resolvedContext ? ` (could not find ${baseUri})` : ''
      throw new Error(`[Resolver] : Cannot resolve reference ${uri}${details}`)
    }
    return { context: resolvedContext, schema }
  }

  public resolveRemoteSchema(url) {
    const cachedSchema = this.store.getRecord(url)
    if (cachedSchema) {
      return cachedSchema
    }
    return this.store.findSchema(url).then((resolvedReference) => {
      // TODO(Peter): revisit this
      return this.resolveSchema(resolvedReference.content)
    })
  }

  // given a context (full schema) and a path, return the subschema
  // resolve path
  public resolveSubschemaByPath(context, path) {
    // if context is an uuid or a metadata id we will try to resolve it
    // e.g. /1.0/entities/metadata/brokerOrder.json
    if (_.isString(context)) {
      context = this.store.getRecord(context)
    }
    if (!_.isArray(path)) {
      path = path.split('.')
    }
    return this.resolveSubschemaByPathHelper(context, context, path)
  }

  public resolveSubschemaByValuePath(context, valuePath) {
    if (_.isString(context)) {
      context = this.store.getRecord(context)
    }
    const path = JSONSchemaResolver.getSchemaPathFromValuePath(valuePath)
    return this.resolveSubschemaByPathHelper(context, context, path)
  }

  private extractReferenceUrl(reference) {
    if (reference[0] !== '#') {
      const hashIndex = reference.indexOf('#')
      if (hashIndex > 0) {
        reference = reference.substring(0, hashIndex)
      }
      return reference
    }
  }

  private findRemoteRefsInArray(array, results) {
    array.map((item) => {
      if (_.isPlainObject(item)) {
        this.findRemoteRefsInObject(item, results)
      }
    })
  }

  private findRemoteRefsInObject(obj, results) {
    _.forIn(obj, (value, key) => {
      if (key === JSONSchemaResolver.REF_KEY) {
        const url = this.extractReferenceUrl(value)
        if (url && !results.hasOwnProperty(url)) {
          results[url] = undefined
        }
      } else if (_.isArray(value)) {
        this.findRemoteRefsInArray(value, results)
      } else if (_.isPlainObject(value)) {
        this.findRemoteRefsInObject(value, results)
      }
    })
  }

  // resolve given a context, partial result so far, rest of path
  private resolveSubschemaByPathHelper(context, partial, path) {
    if (_.isNil(partial)) {
      return
    }

    if (partial[path[0]]) {
      const nextPartial = this.resolveSubschema(context, partial[path[0]])
      if (path.length === 1) {
        return nextPartial
      }

      const result = this.resolveSubschemaByPathHelper(
        nextPartial.context, nextPartial.schema, path.slice(1))
      if (result) {
        return result
      }
    }

    if (partial.oneOf || partial.allOf || partial.anyOf) {
      const branches = [].concat(
        partial.anyOf || [],
        partial.allOf || [],
        partial.oneOf || [],
      ).reverse()

      for (const branch of branches) {
        const branchSchema = this.resolveSubschema(context, branch)
        const result = this.resolveSubschemaByPathHelper(
          branchSchema.context, branchSchema.schema, path)
        if (result) {
          return result
        }
      }
    }
  }

}
