import {
  OverlayToaster,
  OverlayToasterProps,
  ToastProps,
  Toaster,
  ToasterPosition,
} from '@blueprintjs/core'
import React from 'react'
import classNames from 'classnames'

import 'browser/components/atomic-elements/atoms/toast/_toast.scss'

export { Position } from '@blueprintjs/core'

export interface IToastProps extends ToastProps, OverlayToasterProps {
  isLightMode?: boolean
  isFullWidth?: boolean
}

export class ToastSingleton {
  private toasters: Record<string, Toaster>

  constructor() {
    this.toasters = {}
  }

  public dismiss({ position, toastKey }) {
    const toaster = this.getToaster(position)
    return toaster.dismiss(toastKey)
  }

  public show(props: IToastProps) {
    const toaster = this.getToaster(props.position, props.isLightMode, props.isFullWidth)
    const toastKey = toaster.show(props)
    return {
      position: props.position,
      toastKey,
    }
  }

  public update({ position, toastKey }, props) {
    const toaster = this.getToaster(position)
    toaster.show(props, toastKey)
  }

  private getToaster(position: ToasterPosition, isLightMode=false, isFullWidth=false) {
    if (!this.toasters[position.toString()]) {
      this.toasters[position.toString()] = OverlayToaster.create({
        className: classNames('c-toast', {
          'c-toast--light': isLightMode,
          'c-toast--fullWidth': isFullWidth
        }),
        position,
      })
    }
    return this.toasters[position.toString()]
  }
}

export const Toast = new ToastSingleton()
