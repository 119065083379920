import classNames from 'classnames'
import React, { useContext, useEffect, useState } from 'react'
import _ from 'lodash'

import { CommentEntity } from 'shared-libs/generated/server-types/entity/commentEntity'

import { Avatar } from 'browser/components/atomic-elements/atoms/avatar/avatar'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { ImageEditorModal } from '../../image-editor-modal'
import { ThumbnailList } from 'browser/components/atomic-elements/molecules/thumbnail/thumbnail-list'
import { useForceUpdate } from 'browser/components/hooks/useForceUpdate'
import { renderAuthorHeader, renderEventTimestamp } from '../util'
import { AbstractSettings } from 'shared-libs/models/abstract-settings'
import { ComponentsContext } from 'browser/contexts/components/components-context'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'
import moment from 'moment-timezone'
import { Position, Tooltip } from '@blueprintjs/core'
import { TranslationManager } from 'browser/app/utils/translation-manager'
import { RemoteConfig } from 'browser/app/pages/app/tools/remote-config'
import { settings } from 'cluster'
import apis from 'browser/app/models/apis'

interface ICommentItemProps extends IBaseProps {
  item: any
  isFirst: boolean
  // Comments-list does not have a concept of last yet
  isLast?: boolean
  settings?: AbstractSettings
}

export const CommentItem: React.FC<ICommentItemProps> = (props) => {
  const { platform } = useContext(ComponentsContext)
  const { className, item, settings } = props
  const eventUserId = item.owner?.user?.entityId
  const currentUserId = settings?.getUser()?.uniqueId
  const isMine = eventUserId === currentUserId && !_.isEmpty(currentUserId)
  return (
    <div
      className={classNames('c-timelineEvent', className, {
        'c-timelineEvent--isMine': isMine,
      })}
      key={item.uniqueId}
    >
      {platform === PlatformType.MOBILE_WEB ? (
        <MobileCommentItem {...props} isMine={isMine} />
      ) : (
        <DesktopCommentItem {...props} />
      )}
    </div>
  )
}

const DesktopCommentItem = (props: ICommentItemProps) => {
  const { className, item, isFirst } = props
  const { createdBy, creationDate, owner } = item

  const browserLocale = navigator.language
  const commentLocale = item.comment.locale
  const fromString = commentLocale ? `from "${commentLocale}"` : 'comment'

  const tooltip = (!_.isEmpty(commentLocale) && (!browserLocale.startsWith(commentLocale) || !commentLocale.startsWith(browserLocale)))
    ? (
      `Translated ${fromString}, original text "${item.comment.text}"`
    )
    : null
  return (
    <Tooltip
      position={Position.TOP}
      content={tooltip}
      hoverOpenDelay={200}
    >
      <>
        <div
          className={classNames('c-timelineEvent-line', className, {
            'c-timelineEvent-line--topCutOff': isFirst,
          })}
        />
        <div className="c-timelineEvent-dot" />
        <div className="c-timelineEvent-contentContainer">
          <div className="c-timelineEvent-header">
            <div className="c-timelineEvent-author">
              <Avatar className="c-timelineEvent-avatar" name={createdBy.displayName} size="xs" />
              <Label className="c-timelineEvent-authorName">
                {renderAuthorHeader(createdBy, owner?.firm)}
              </Label>
            </div>
            <HelpBlock className="c-timelineEvent-timestamp">
              {renderEventTimestamp(creationDate)}
            </HelpBlock>
          </div>
          <div className="c-timelineEvent-bodyContainer">
            <CommentMediaGroup entity={item} />
            <CommentTextItem entity={item} />
          </div>
        </div>
      </>
    </Tooltip>
  )
}

const MobileCommentItem = (props: ICommentItemProps & { isMine: boolean }) => {
  const { item, isMine } = props
  const { createdBy } = item
  return (
    <>
      <div className="c-timelineEvent-contentContainer">
        <div className="c-timelineEvent-header">
          {!isMine && (
            <div className="c-timelineEvent-author">
              <Avatar className="c-timelineEvent-avatar" name={createdBy.displayName} size="xs" />
            </div>
          )}
        </div>
        <div className="c-timelineEvent-bodyContainer">
          <CommentMediaGroup entity={item} />
          <CommentTextItem entity={item} />
        </div>
      </div>
    </>
  )
}

const CommentMediaGroup: React.FC<{ entity: CommentEntity }> = ({ entity }) => {
  const forceUpdate = useForceUpdate()
  const files = entity.comment.media?.files
  if (!files) {
    return null
  }
  const handleClick = (idx) => {
    ImageEditorModal.open({
      entity,
      imagesPath: 'comment.media',
      index: idx,
      onClose: forceUpdate,
      isEditable: false,
    })
  }
  return (
    <ThumbnailList
      files={files}
      showOriginal={true}
      size="xl"
      fitIn={120}
      onThumbnailClick={(evt, file, index) => handleClick(index)}
    />
  )
}

const CommentTextItem: React.FC<{ entity: CommentEntity }> = ({ entity }) => {
  // TODO: for now, only implemented for comments to support mobile-web's
  // grouped timestamp UI. If we want this feature on web, we should also
  // refactor to standardize these items to share common features.
  const [showTimeOfDay, toggleShowTimeOfDay] = useState(false)
  const [text, setText] = useState(entity.comment.text)
  const [translated, setTranslated] = useState(false)
  useEffect(() => {
    const shouldTranslate = apis.getSettings().getRemoteConfigValue('translateComments', false).asBoolean()
    if (!shouldTranslate) {
      return
    }
    const targetLocale = navigator.language
    const sourceLocale = entity.comment.locale
    if (_.isEmpty(text) || translated) {
      return
    }
    TranslationManager.translateString(entity.comment.text, sourceLocale, targetLocale)
      .then((translated) => {
        setTranslated(true)
        setText(translated)
      })
  }, [translated, entity.comment.locale, text])
  if (!text) {
    return null
  }
  const isTranslated = (text !== entity.comment.text)

  return (
    <>
      <div className={
        classNames("c-timelineEvent-body", {
          'c-timelineEvent-translated': isTranslated
        })
      } onClick={() => toggleShowTimeOfDay(!showTimeOfDay)}>
        <p> {text} </p>
      </div>
      {showTimeOfDay && <TimeOfDay entity={entity} />}
    </>
  )
}

const TimeOfDay: React.FC<{ entity: CommentEntity }> = ({ entity }) => {
  const { platform } = useContext(ComponentsContext)
  if (platform !== PlatformType.MOBILE_WEB) {
    return null
  }
  return (
    <div className="c-timelineEvent-body-timeOfDay">
      {moment(_.get(entity, 'creationDate')).format('h:mm a')}
    </div>
  )
}
