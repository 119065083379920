import { Classes, Icon } from '@blueprintjs/core'
import { IconNames } from '@blueprintjs/icons'
import classNames from 'classnames'
import _ from 'lodash'
import React from 'react'
import Dropzone from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'

import { Logger } from 'browser/apis/logging'
import { IBaseProps } from 'browser/components/atomic-elements/atoms/base-props'
import { Button } from 'browser/components/atomic-elements/atoms/button/button'
// tslint:disable-next-line:max-line-length
import { FormGroupContentWrapper } from 'browser/components/atomic-elements/atoms/form-group-content-wrapper/form-group-content-wrapper'
import { HelpBlock } from 'browser/components/atomic-elements/atoms/help-block/help-block'
import { Label } from 'browser/components/atomic-elements/atoms/label/label'
import { Popover } from 'browser/components/atomic-elements/atoms/popover/popover'
import { TetherTarget } from 'browser/components/atomic-elements/atoms/tether-target'
import { Thumbnail } from 'browser/components/atomic-elements/molecules/thumbnail/thumbnail'
import { ConfirmationModal, IConfirmationModalProps } from 'browser/components/atomic-elements/organisms/confirmation-modal'
import { ImageEditorModal } from 'browser/components/atomic-elements/organisms/image-editor-modal'
import { IImageEditorCarouselProps } from 'browser/components/atomic-elements/organisms/image-editor-carousel/image-editor-carousel'
import 'browser/components/json-elements/atoms/file-input/_file-input.scss'
import { TryAsync, translateString } from 'shared-libs/helpers/utils'
import { convertFile, detectWebpSupport, getFileMimeType } from 'browser/app/utils/image'
import { DocumentCamera, IDocumentCaptureResult } from 'browser/components/json-elements/atoms/document-camera/document-camera'
import { ADAPTIVE_THRESHOLD_BOX_SIZE, ADAPTIVE_THRESHOLD_VALUE, applyPerspective, grayscaleFile } from 'browser/mobile/util/page-utils'
import { withContext } from 'shared-libs/components/context/with-context'
import { ComponentsContext, IComponentsContext } from 'browser/contexts/components/components-context'
import { PlatformType } from 'shared-libs/models/types/storyboard/storyboard-plan'
import { Checkbox } from 'browser/components/atomic-elements/atoms/checkbox/checkbox'
import { ErrorText } from 'browser/app/pages/app/tools/errors'

const USE_LEGACY_FILE_INPUT = false

const MIN_THRESHOLD_VALUE = 5
const VARIABLE_THRESHOLD_VALUE = 25
const MIN_OFFSET_VALUE = 2
const VARIABLE_OFFSET_VALUE = 10

const dropdownTetherOptions = {
  attachment: 'top right',
  targetAttachment: 'bottom right',
}

/**
 * @uiComponent
 */
export interface IFileInputProps extends IBaseProps {
  frames?: any
  entity: any
  errors?: object[]
  buttonClassName?: string
  itemDeleteButtonClassName?: string
  emptyCueButtonText?: string
  emptyCueHelpText?: string
  enableAutoGrayscale?: boolean
  enablePageDetection?: boolean
  addFilesButtonText?: string
  addFilesButtonSize?: string
  addFilesButtonClassName?: string
  showItemDeleteButton?: boolean
  showOptionsButton?: boolean
  showAddButton?: boolean
  isEditable?: boolean
  isHorizontalLayout?: boolean
  inputClassName?: string
  label?: string
  isMultiFile?: boolean
  accept?: string | string[]
  onChange: (value: object | object[]) => void
  showFileName?: boolean
  size?: string
  value: any[] | any
  valuePath?: string
  carouselProps?: Partial<IImageEditorCarouselProps>
  confirmationDialogProps?: Partial<IConfirmationModalProps>
  showLibraryPhoto?: boolean
}

interface ContextProps {
  componentsContext?: IComponentsContext
}

interface IFileInputState {
  showCamera: boolean
  isCameraSettingUp: boolean
  openDropzone: boolean
  error?: Error
  thumbnailSize?: string
  forceLegacyCapture: boolean
  useLegacyDocumentCapture?: boolean
}

const PREVIEWS_PAGE_LIMIT = 25

@withContext(ComponentsContext, 'componentsContext')
export class FileInput extends React.Component<IFileInputProps & ContextProps, IFileInputState> {
  public static defaultProps: Partial<IFileInputProps> = {
    addFilesButtonText: 'Add additional files',
    addFilesButtonSize: 'small',
    emptyCueButtonText: 'Upload File',
    emptyCueHelpText: 'Drop a PDF, multiple images, or click to select your file(s)',
    showAddButton: true,
    showFileName: true,
    showOptionsButton: true,
    size: 'lg',
    carouselProps: {},
    showLibraryPhoto: true,
    isEditable: true,
  }

  private dropzone: Dropzone
  private track?: MediaStreamTrack
  private isWebpSupported: boolean

  constructor(props: IFileInputProps & ContextProps) {
    super(props)
    this.state = {
      showCamera: false,
      isCameraSettingUp: true,
      openDropzone: false,
      forceLegacyCapture: false,
    }
  }

  public async componentDidMount(): Promise<void> {
    this.isWebpSupported = await detectWebpSupport()
    const { entity, componentsContext: { platform } } = this.props
    const defaultDocumentCapture = entity?.api.settings
      .getRemoteConfigValue('useLegacyDocumentCapture', USE_LEGACY_FILE_INPUT)
      .asBoolean()

    const useLegacyDocumentCapture =
      defaultDocumentCapture || platform === PlatformType.WEB || !(await this.isCameraAvailable())

    this.setState({ useLegacyDocumentCapture })
  }

  private isCameraAvailable = () => {
    return DocumentCamera.isCameraAvailable().catch((e) => {
      console.warn(e.message)
    })
  }

  public componentWillUnmount(): void {
    this.track?.stop()
  }

  public render(): JSX.Element {
    const { className, inputClassName, isHorizontalLayout, isMultiFile, accept } = this.props
    const errorText = this.getErrorText()

    return (
      <FormGroupContentWrapper
        className={classNames(className, inputClassName, {
          'c-formGroupContentWrapper--noBottomBorder': !isHorizontalLayout,
        })}
        isHorizontalLayout={isHorizontalLayout}
        hasError={!_.isEmpty(errorText)}
      >
        <Dropzone
          data-debug-id='fileInput:Attachments'
          activeClassName='is-active'
          accept={accept}
          activeStyle={{}}
          className={classNames('c-fileInput', {
            'c-fileInput--error': !_.isEmpty(errorText),
            'c-fileInput--isHorizontalLayout': isHorizontalLayout,
            'c-fileInput--singleFile': !isMultiFile,
          })}
          disableClick={true}
          disablePreview={true}
          onDrop={this.handleDrop}
          ref={this.handleDropzoneRef}
        >
          {({ isDragActive, isDragReject }) => {
            return this.renderContent(isDragActive, isDragReject)
          }}
        </Dropzone>
      </FormGroupContentWrapper>
    )
  }

  private handleDropzoneRef = (ref) => {
    if (ref) {
      this.dropzone = ref
    }
    if (this.state.openDropzone && this.dropzone) {
      this.dropzone.open()
      this.setState({ openDropzone: false })
    }
  }

  private handleAddContent = () => {
    const { showCamera, forceLegacyCapture, useLegacyDocumentCapture } = this.state
    const { componentsContext: { platform } } = this.props

    if (showCamera) {
      return
    }

    if (useLegacyDocumentCapture || forceLegacyCapture || platform !== PlatformType.MOBILE_WEB) {
      this.handleDropzoneClick()
    } else {
      const componentsContext = {
        ...this.props.componentsContext,
        platform: this.props.componentsContext.platform
      }
      DocumentCamera.open(
        {
          ...this.props,
          onImageCaptured: this.handleImageCaptured,
          openLibrary: this.handleDropzoneClick,
          onCancel: this.handleCancelClick,
        },
        this.props.entity.api.settings,
        componentsContext,
      )
    }
  }

  private handleDropzoneClick = () => {
    this.setState({ showCamera: false, openDropzone: true })
    this.dropzone?.open()
  }

  private handleImageCaptured = async (image: IDocumentCaptureResult) => {
    const { isMultiFile, onChange, entity, valuePath, enableAutoGrayscale, isEditable } = this.props
    this.setState({ showCamera: false })

    const multipartFiles = []
    const filesList = []

    const originalFile = image.imageFile
    const originalURL = URL.createObjectURL(originalFile)
    const uniqueId = uuidv4()
    const originalBitmap = await createImageBitmap(originalFile)

    const transform = _.isEmpty(image?.bounds) ? null : {
      type: 'perspective',
      topLeft: image.bounds[0],
      topRight: image.bounds[1],
      bottomRight: image.bounds[2],
      bottomLeft: image.bounds[3]
    }

    try {
      const previewFile = enableAutoGrayscale
        ? await grayscaleFile(originalURL, originalFile.name,
          ADAPTIVE_THRESHOLD_VALUE, ADAPTIVE_THRESHOLD_BOX_SIZE,
          this.props.componentsContext.platform === PlatformType.MOBILE_WEB)
        : originalFile
      const previewUri = URL.createObjectURL(previewFile)
      const transformFile = transform === null ? previewFile : await applyPerspective(previewUri, transform, previewFile.name)
      const transformUri = transform === null ? previewUri : URL.createObjectURL(transformFile)

      const transforms: any[] = []
      transform && transforms.push(transform)
      if (enableAutoGrayscale) {
        transforms.push(this.getDefaultThresholdTransform())
      }
      multipartFiles.push({
        file: originalFile,
        uniqueId,
        uri: originalURL,
        transformations: transforms,
        transformUri,
      })
      filesList.push({
        name: transformFile.name,
        type: this.getFileType(image.originalMimeType),
        uniqueId,
        uri: originalURL,
        source: [{
          width: originalBitmap.width,
          height: originalBitmap.height,
          uri: transformUri || originalURL,
        }],
        width: originalBitmap.width,
        height: originalBitmap.height,
        isEditable: isEditable,
        transformations: transforms,
        transformUri,
      })
      if (isMultiFile) {
        let value = this.props.value || []
        value = value.concat(filesList)
        onChange(value)
      } else {
        onChange(filesList)
      }
      entity.addMultipartFilesFromBlob(multipartFiles[0], valuePath)
    } catch (error) {
      console.error(`Error adding captured image: ${error.stack}`)
      this.setState({ error})
    }
  }

  private handleCancelClick = (error?:Error) => {
    const errorDict = _.isNil(error) ? null
      : { error, forceLegacyCapture: true }
    console.log(`cancel clicked, error ${error?.message}`)
    this.setState({ showCamera: false, ...errorDict })
  }

  private getErrorText = () => {
    const { errors=[] } = this.props
    const { error } = this.state

    error && errors.push(error)

    return _.isEmpty(errors) ? null : (
      <ErrorText>
      {_.map(errors,
        (error:any) => error.stack || error.message || error.toString?.() || error).join('\n') || ''}
      </ErrorText>
    )
  }

  private renderContent(isDragActive, isDragReject) {
    const { useLegacyDocumentCapture } = this.state
    const {
      frames,
      isMultiFile,
      showAddButton,
      addFilesButtonText: addFilesText,
      addFilesButtonClassName,
      addFilesButtonSize,
    } = this.props
    let { value } = this.props
    const errorText = this.getErrorText()

    const translationTable = frames?.getContext('translationTable')

    if (!isMultiFile && !_.isArray(value)) {
      value = value ? [value] : []
    }
    let overlay = null
    if (isDragActive) {
      overlay = (
        <div className='c-fileInputOverlay'>
          <HelpBlock>
            Drop to attach files
          </HelpBlock>
        </div>
      )
    }
    if (isDragReject) {
      overlay = (
        <div className='c-fileInputOverlay'>
          <HelpBlock>
            Something doesn{"'"}t look right...
          </HelpBlock>
        </div>
      )
    }

    let content = null
    let addAdditionalCue = null
    if (_.isEmpty(value)) {
      content = this.renderEmptyState()
    } else {
      content = value.map(this.handleRenderFilePreviewItem)
      if (showAddButton && isMultiFile) {
        const translatedText = translateString(addFilesText, translationTable)
        addAdditionalCue = (
          <div className='mv2 tr'>
            <Button
              className={classNames(
                addFilesButtonClassName,
                Classes.iconClass(IconNames.ADD),
                Classes.MINIMAL
              )}
              onClick={this.handleAddContent}
              size={addFilesButtonSize}
            >
              {translatedText}
            </Button>
            {errorText}
          </div>
        )
      }
    }
    const forceLegacyText = "Use file picker"
    const translatedLegacyText = translateString(forceLegacyText, translationTable)
    const isForceLegacySelected = this.state.forceLegacyCapture
    const forceLegacy = useLegacyDocumentCapture !== false ? null : (
      <div className='mv2 tr'>
        <Checkbox
          onClick={ () => this.setState({ forceLegacyCapture: !isForceLegacySelected }) }
          value={isForceLegacySelected}
          label={translatedLegacyText}
        />
      </div>
    )

    return (
      <div className='c-fileInputItems w-100'>
        {content}
        {addAdditionalCue}
        {forceLegacy}
        {overlay}
      </div>
    )
  }

  private renderDropdown(file, index) {
    const url = _.get(file, 'source[0].uri', '')
    const onClick = () => this.handleShowDeleteFilePrompt(file, index)
    const logDownloadPage = () => Logger.logEvent('Download Page')
    const logDownloadOriginal = () => Logger.logEvent('Download Original')

    const downloadPageLink = (
      <a
        className='c-dropdownList-item u-displayBlock'
        download={true}
        href={url}
        onClick={logDownloadPage}
        target='_blank'
        rel='noopener noreferrer'
      >
        Download page
      </a>
    )

    return (
      <Popover
        className='collapse'
      >
        <ul className='c-dropdownList'>
          {file.type === 'image' ? downloadPageLink : null}
          <a
            className='c-dropdownList-item u-displayBlock'
            download={true}
            href={file.uri}
            onClick={logDownloadOriginal}
            target='_blank'
            rel='noopener noreferrer'
          >
            Download original
          </a>
          <li className='c-list-divider' />
          <li
            className='c-dropdownList-item'
            onClick={onClick}
          >
            Delete page
          </li>
        </ul>
      </Popover>
    )
  }

  private renderEmptyState() {
    const { buttonClassName, emptyCueHelpText, emptyCueButtonText, frames } = this.props
    const errorText = this.getErrorText()
    return (
      <div className='pv3 tc'>
        <div className='mb3'>
          <HelpBlock
            frames={frames}
          >
            {emptyCueHelpText}
          </HelpBlock>
        </div>
        <Button
          className={classNames(buttonClassName, Classes.iconClass(IconNames.UPLOAD))}
          onClick={this.handleAddContent}
          frames={frames}
        >
          {emptyCueButtonText}
        </Button>
        {errorText}
      </div>
    )
  }

  private handleRenderFilePreviewItem = (file, index) => {
    const { size } = this.props
    const handleRemove = () => this.handleShowDeleteFilePrompt(file, index)
    const handlePreview = () => this.handleThumbnailClick(file, index)
    let preview
    if (file.type === 'pdf') {
      preview = (
        <div
          className='pa1 c-fileInputItemPreview c-fileInputItemPreview--pdf pointer'
          onClick={handlePreview}
        />
      )
    } else if (file.type === 'image') {
      preview = (
        <Thumbnail
          imageClassName='c-fileInputItemPreview c-fileInputItemPreview--image'
          onClick={handlePreview}
          file={file}
          size={size}
        />
      )
    } else {
      preview = (
        <div
          className='pa1 c-fileInputItemPreview c-fileInputItemPreview--file'
        />
      )
    }
    return (
      <div
        className='c-fileInputItem-container pv2'
        key={index}
      >
        <div className='c-fileInputItemPreview-wrapper'>
          <div className='c-fileInputItemPreview-relative'>
            {preview}
          </div>
        </div>
        {this.renderFileName(file)}
        {this.renderItemDeleteButton(handleRemove)}
        {this.renderOptions(file, index)}
      </div>
    )
  }

  private renderFileName(file) {
    const { showFileName } = this.props

    if (showFileName) {
      return (
        <Label className='c-label--heading collapse u-ellipsis w-100 ml3 c-fileInputItem-name'>
          {file.name}
        </Label>
      )
    }
  }

  private renderOptions(file, index) {
    const { showOptionsButton } = this.props
    if (showOptionsButton) {
      return (
        <TetherTarget
          closeOnPortalClick={true}
          tethered={this.renderDropdown(file, index)}
          tetherOptions={dropdownTetherOptions}
        >
          <div className={classNames(Classes.BUTTON, Classes.MINIMAL)}>
            <Icon
              icon={IconNames.MORE}
            />
          </div>
        </TetherTarget>
      )
    }
  }

  private renderItemDeleteButton(onClick) {
    const { itemDeleteButtonClassName, showItemDeleteButton } = this.props
    if (showItemDeleteButton) {
      return (
        <Button
          className={classNames(itemDeleteButtonClassName, Classes.MINIMAL)}
          onClick={onClick}
        >
          <Icon icon={IconNames.CROSS} />
        </Button>
      )
    }
  }

  private getFileType(mimeType) {
    if (mimeType.match(/^application\/pdf/)) {
      return 'pdf'
    } else if (mimeType.match(/^image/)) {
      return 'image'
    } else {
      return 'generic'
    }
  }

  private handleDrop = async (files: File[]) => {
    if (_.isEmpty(files)) {
      return
    }

    this.setState({ showCamera: false, openDropzone: false })

    const { entity, isMultiFile, onChange, valuePath, isEditable } = this.props

    const multipartFiles = []
    const filesList = []

    for (const originalFile of files) {
      const uniqueId = uuidv4()
      const detectedMimeType = await getFileMimeType(originalFile)

      const isImage = /^image/.test(detectedMimeType)
      const shouldConvertToWebp = isImage && this.isWebpSupported
      const file = shouldConvertToWebp ? await convertFile(originalFile, 'image/webp') : originalFile
      const imageBitmap = isImage && await TryAsync(() => createImageBitmap(file))
      const processedURL = URL.createObjectURL(file)
      const imageProperties = imageBitmap && {
        source: [{
          width: imageBitmap.width,
          height: imageBitmap.height,
          uri: processedURL,
        }],
        width: imageBitmap.width,
        height: imageBitmap.height,
      }

      multipartFiles.push({
        file,
        uniqueId,
      })
      filesList.push({
        name: file.name,
        type: this.getFileType(detectedMimeType),
        uniqueId,
        uri: processedURL,
        ...imageProperties,
        isEditable: isEditable
      })
    }

    if (isMultiFile) {
      let value = this.props.value || []
      value = value.concat(filesList)
      onChange(value)
    } else {
      onChange(filesList)
    }
    entity.addMultipartFiles(valuePath, multipartFiles)
  }

  private handleRemoveFile = (file, index) => {
    const { isMultiFile, entity, value, onChange, valuePath } = this.props
    entity.removeMultipartFileByIds(valuePath, file.uniqueId)
    if (isMultiFile) {
      const contentValue = value || []
      _.pull(contentValue, file)
      onChange(contentValue)
    } else {
      onChange(undefined)
    }
  }

  private handleShowDeleteFilePrompt = (file, index) => {
    const { frames } = this.props
    const translationTable = frames?.getContext('translationTable')
    const translatedText = translateString('Do you want to delete this image?', translationTable)
    const translatedTitle = translateString('Review Changes', translationTable)
    const translatedPrimaryButtonText = translateString('Confirm', translationTable)
    const translatedCancelButtonText = translateString('Cancel', translationTable)
    ConfirmationModal.open({
      confirmationText: translatedText,
      confirmationTitle: translatedTitle,
      modalDialogClassName: 'c-modal-dialog--sm',
      onPrimaryClicked: () => this.handleRemoveFile(file, index),
      primaryButtonText: translatedPrimaryButtonText,
      cancelButtonText: translatedCancelButtonText,
      ...(this.props.confirmationDialogProps || {}),
    })
  }

  private handleThumbnailClick = (file, index) => {
    const { useLegacyDocumentCapture } = this.state
    const { frames, entity, carouselProps, isEditable } = this.props
    const fileType = _.get(file, 'type', null)
    const useWebGLPreview = !useLegacyDocumentCapture &&
      !this.state.forceLegacyCapture &&
      (this.props.componentsContext.platform === PlatformType.MOBILE_WEB)

    // Frame is passed in by entity renderer, if it is not available,
    // don't allow opening ImageEditorModal
    if (!frames) { return }

    // There could be a problem here when the attachments
    // are not on the 'document.attachments' path
    // Here we are assuming the previews are always on document.attachments
    // but technically attachments can also be on a different path
    // Like... Daily Pay Sheet 2019 or C&S Field Audit
    // If we do run into a situation where there is a PDF, attached to a different path
    // we just dont show the preview since the aggregateNumberOfPages is equal to 26
    // TODO(David): Need to look at this and understand what the user expereince should be when dealing with
    // big pdfs
    if (fileType === 'pdf') {
      // If we have a pdf that has more than 25 pages on a differnt path we dont accmodate that yet
      const aggregateNumberOfPages = _.get(entity, 'document.attachments.aggregate.pages', null)
      if (aggregateNumberOfPages && aggregateNumberOfPages > PREVIEWS_PAGE_LIMIT) { return }
      if (file.pages > PREVIEWS_PAGE_LIMIT) { return }
    }
    const schema = frames.getContext('dataSchema')
    const imagesPath = frames.getContext('valuePath').join('.')
    const componentsContext = {
      ...this.props.componentsContext,
      platform: this.props.componentsContext.platform
    }
    ImageEditorModal.open({
      ...carouselProps,
      entity,
      schema,
      imagesPath,
      index,
      useWebGLPreview,
      isEditable,
      onClose: () => this.forceUpdate(),
    }, componentsContext)
  }

  private getDefaultThresholdTransform(value = 0.5) {
    const result = {
      type: 'threshold',
      size: Math.floor(MIN_THRESHOLD_VALUE + value * VARIABLE_THRESHOLD_VALUE),
      offset: Math.floor(MIN_OFFSET_VALUE + value * VARIABLE_OFFSET_VALUE),
      value: value
    }
    if (result.size % 2 === 0) {
      result.size += 1
    }
    return result
  }

}
