import _ from 'lodash'
import { fileTypeFromBuffer } from 'file-type'
import { RemoteFile } from 'shared-libs/generated/server-types/entity/commentEntity'

export function isZoomable(image, padding, containerWidth, containerHeight) {
  const imageWidth = image.width
  const imageHeight = image.height
  const adjContainerWidth = containerWidth - padding * 2
  const adjContainerHeight = containerHeight - padding * 2
  return imageWidth > adjContainerWidth || imageHeight > adjContainerHeight
}

export function getMinZoomLevel(image, padding, containerWidth, containerHeight) {
  if (!image || !image.width || !image.height) {
    return 1 // default zoom value
  }

  let imageWidth = image.width
  let imageHeight = image.height
  const aspectRatio = imageHeight / imageWidth
  const adjContainerWidth = containerWidth - padding * 2
  const adjContainerHeight = containerHeight - padding * 2
  if (imageWidth > adjContainerWidth) {
    imageWidth = adjContainerWidth
    imageHeight = imageWidth * aspectRatio
  }
  if (imageHeight > adjContainerHeight) {
    imageHeight = adjContainerHeight
    imageWidth = imageHeight / aspectRatio
  }
  return imageWidth / image.width
}

export function getFitWidthZoomLevel(image, padding, containerWidth) {
  let imageWidth = image.width
  const adjContainerWidth = containerWidth - padding * 2
  imageWidth = adjContainerWidth
  return imageWidth / image.width
}

/* from https://developers.google.com/speed/webp/faq#in_your_own_javascript */
export async function detectWebpSupport(
  feature: 'lossy' | 'lossless' | 'alpha' | 'animation' = 'lossy'
): Promise<boolean> {
  return new Promise((resolve) => {
    const kTestImages = {
      lossy: 'UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA',
      lossless: 'UklGRhoAAABXRUJQVlA4TA0AAAAvAAAAEAcQERGIiP4HAA==',
      alpha:
        'UklGRkoAAABXRUJQVlA4WAoAAAAQAAAAAAAAAAAAQUxQSAwAAAARBxAR/Q9ERP8DAABWUDggGAAAABQBAJ0BKgEAAQAAAP4AAA3AAP7mtQAAAA==',
      animation:
        'UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA',
    }
    const img = new Image()
    img.onload = function () {
      const result = img.width > 0 && img.height > 0
      resolve(result)
    }
    img.onerror = function () {
      resolve(false)
    }
    img.src = 'data:image/webp;base64,' + kTestImages[feature]
  })
}

export async function convertFile(
  file: File,
  contentType: string,
  quality: number = 0.8
) {
  const ext = _.last(contentType.split('/'))
  const newName = changeFileExtension(file.name, ext)

  try {
    const image = await getImageFromFile(file)
    const blob = await convertImage(image, contentType, quality)
    return new File([blob], newName, { type: contentType })
  } catch (e) {
    // if conversion fails, fall back to input file
    return file
  }
}

async function getImageFromFile(file): Promise<HTMLImageElement> {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.onerror = (evt) => {
      reject(new Error('failed to load image'))
    }
    img.onload = () => {
      resolve(img)
    }
    img.src = URL.createObjectURL(file)
  })
}

async function convertImage(
  sourceImage: HTMLImageElement,
  destinationContentType: string,
  quality: number
): Promise<Blob> {
  return new Promise((resolve) => {
    const canvas = document.createElement('canvas')
    canvas.width = sourceImage.width
    canvas.height = sourceImage.height
    const ctx = canvas.getContext('2d')
    ctx.drawImage(sourceImage, 0, 0)
    canvas.toBlob(
      (blob) => {
        resolve(blob)
      },
      destinationContentType,
      quality
    )
  })
}

export function changeFileExtension(filename: string, ext: string): string {
  const extPattern = /\.[^/.]+$/
  if (extPattern.test(filename)) {
    return filename.replace(extPattern, '.webp')
  } else {
    return `${filename}.webp`
  }
}

export async function getFileMimeType(file: File): Promise<string> {
  const headerBlob = file.slice(0, 4100) // minimum bytes
  const reader = new FileReader()
  return new Promise((resolve, reject) => {
    reader.onerror = (evt) => {
      reject(evt.target.error)
    }
    reader.onloadend = async (evt) => {
      const bytes = new Uint8Array(evt.target.result as ArrayBuffer)
      const result = await fileTypeFromBuffer(bytes)
      const mimeType = result?.mime ?? 'application/octet-stream'
      resolve(mimeType)
    }
    reader.readAsArrayBuffer(headerBlob)
  })
}

export function getFileType(mimeType: string): RemoteFile['type'] {
  if (mimeType.match(/^application\/pdf/)) {
    return 'pdf'
  } else if (mimeType.match(/^image/)) {
    return 'image'
  } else {
    return 'generic'
  }
}
